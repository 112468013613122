import { weddingDate } from './constants';

import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function Nav() {


  return (
    <Toolbar>
      <Typography
        component="h2"
        variant="h5"
        color="inherit"
        align="left"
        noWrap
        sx={{ flex: 1 }}
      >
        Liam & Jessica
      </Typography>
      <Typography
        component="h2"
        variant="h5"
        color="inherit"
        align="left"
        noWrap
        sx={{ flex: 1 }}
      >
        {weddingDate}
      </Typography>
      <Button variant="text" color='success'>Location</Button>
      <Button variant="text" color='success'>Gifts</Button>
      <Button variant="outlined" color='success'>RSVP</Button>
    </Toolbar>
  )
}

export default Nav;
