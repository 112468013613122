import { Paper } from '@mui/material';
import './Homepage.css';
import homepageImage from './homepage-photo.jpg';
import Nav from './Nav';

function Homepage() {
  const imageStyle = {
    maxWidth: '100vw',
    maxHeight: '100vh',
  }

  return (
    <div className="Homepage">
      <Nav />
      <Paper
        sx={{
          position: 'relative',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <img src={homepageImage} alt="Engagement in Sedona" style={imageStyle} />
      </Paper>
    </div>
  );
}

export default Homepage;
